import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledLayout = styled(Layout)`
  height: 100%;

  #components-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 120px;
      height: 31px;
      background: rgba(255, 255, 255, 0.2);
      margin: 16px 24px 16px 0;
      float: left;
    }

    #avatar-profile {
      color: #fff;
      span {
        margin-left: 5px;
      }
    }
  }
`;
