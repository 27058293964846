import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100%;

  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }

  .error-feedback {
    width: 100%;
    color: #ff5767;
  }

  .font-white {
    color: #fff;
  }
`;

export const InputContainer = styled.div`
  /* min-height: 70px; */
  margin-bottom: 10px;
`;

export const Input = styled.input`
  background: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 6px;
  width: 100%;

  font-size: 16px;
  padding: 10px 20px;
`;

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 30px;
`;
