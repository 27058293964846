import { select } from 'redux-saga/effects'

import api from 'services/api'
import { getAuth, getCustomer, getUnit } from './selectors'

export function* init() {
  const auth = yield select(getAuth)
  const customer = yield select(getCustomer)
  const unit = yield select(getUnit)
  if (auth.token) {
    yield (api.defaults.headers.common.Authorization = `Bearer ${auth.token}`)
    yield (api.defaults.headers.common.cli_codigo = customer.code)
    yield (api.defaults.headers.common.plt_codigo = unit.code)
  }
}
