import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale-provider/pt_BR';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga';

import { Store, persistor, history } from './store';
import Routes from './routes';
import GlobalStyles from './styles/global';

ReactGA.initialize('UA-140856867-1');

const App = () => (
  <Fragment>
    <GlobalStyles />
    <Provider store={Store}>
      <ConnectedRouter history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <ConfigProvider locale={locale}>
            <Routes />
          </ConfigProvider>
        </PersistGate>
      </ConnectedRouter>
    </Provider>
  </Fragment>
);

export default App;
