import { call, put, all } from 'redux-saga/effects';

import api from 'services/api';

import { Creators as AuthActions } from '../ducks/auth';
import { Creators as ProfileActions } from '../ducks/profile';

export function* login(action) {
  try {
    const {
      data: { token },
    } = yield call(api.post, '/sessions', action.payload);

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    const { data: profile } = yield call(api.get, '/v1/profile');

    return yield all([
      put(AuthActions.loginSuccess({ token })),
      put(ProfileActions.setProfileData(profile)),
    ]);
  } catch (err) {
    if (err.response) {
      if (Array.isArray(err.response.data)) {
        return yield put(AuthActions.loginFailure(err.response.data));
      }
      return yield put(
        AuthActions.loginFailure(err.response.data.error.message)
      );
    }
    return yield put(
      AuthActions.loginFailure(
        'Houve um problema com nosso servidor, por favor tente novamente, ou mais tarde.'
      )
    );
  }
}

export function* signup(action) {
  try {
    const {
      data: { token },
    } = yield call(api.post, { data: action.payload });

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    yield all([
      put(AuthActions.loginSuccess({ token })),
      // put(ProfileActions.setProfileData(user)),
    ]);
  } catch (err) {
    yield put(
      AuthActions.signupFailure(
        err.response ? err.response.data.msg : 'UNKNOWN_ERROR'
      )
    );
  }
}
