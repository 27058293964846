import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { createBrowserHistory as createHistory } from 'history';
import ReactGA from 'react-ga';

import Layout from 'components/Layout';
import LayoutMaster from 'components/LayoutMaster';

import PrivateRoute from './private';
import Login from '../pages/login/Login';
import Logout from '../pages/login/Logout';

const history = createHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const Customers = lazy(() => import('../pages/customers'));
const CustomerForm = lazy(() => import('../pages/customers/CustomerForm'));
const UnitForm = lazy(() => import('../pages/units/UnitForm'));
const WhiteLabel = lazy(() => import('../pages/white_label'));

const ModulesPlans = lazy(() => import('../pages/ModulesPlans'));
const ModulesPlansForm = lazy(() =>
  import('../pages/ModulesPlans/ModulesPlansForm')
);
const UsersPortal = lazy(() => import('../pages/UsersPortal'));
const UsersPortalForm = lazy(() =>
  import('../pages/UsersPortal/UsersPortalForm')
);

// const LoadingComponent = () => <Skeleton active />

const Dashboard = lazy(() => import('../pages/dashboard'));
const Plans = lazy(() => import('../pages/plans'));
const Log = lazy(() => import('../pages/log'));
const Users = lazy(() => import('../pages/users'));
const DateHour = lazy(() => import('../pages/dateHour'));

const DashboardRoutes = () => (
  <Layout>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/dashboard/log/:page?" component={Log} />
        <PrivateRoute path="/dashboard/plans" component={Plans} />
        <PrivateRoute path="/dashboard/users" component={Users} />
        <PrivateRoute path="/dashboard/date-hour" component={DateHour} />
      </Switch>
    </Suspense>
  </Layout>
);

const AllRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/logout" component={Logout} />

        <LayoutMaster>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <PrivateRoute
                exact
                path="/modules-plans"
                component={ModulesPlans}
              />
              <PrivateRoute
                exact
                path="/modules-plans/new"
                component={ModulesPlansForm}
              />
              <PrivateRoute
                exact
                path="/modules-plans/:id"
                component={ModulesPlansForm}
              />

              <PrivateRoute exact path="/users" component={UsersPortal} />
              <PrivateRoute
                exact
                path="/users/new"
                component={UsersPortalForm}
              />
              <PrivateRoute
                exact
                path="/users/:id"
                component={UsersPortalForm}
              />

              <PrivateRoute exact path="/customers" component={Customers} />
              <PrivateRoute path="/customers/new" component={CustomerForm} />
              <PrivateRoute path="/customers/unit/new" component={UnitForm} />
              <PrivateRoute
                exact
                path="/customers/white-label/:clientCode"
                component={WhiteLabel}
              />

              <PrivateRoute path="/dashboard" component={DashboardRoutes} />
            </Switch>
          </Suspense>
        </LayoutMaster>
      </Switch>
    </Router>
  );
};

export default AllRoutes;
