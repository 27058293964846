import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';

import { StyledLayout } from './styles';

const { Header, Content } = StyledLayout;

const menu = (
  <Menu>
    <Menu.Item>Perfil</Menu.Item>
    <Menu.Divider></Menu.Divider>
    <Menu.Item>
      <NavLink to="/logout">Sair</NavLink>
    </Menu.Item>
  </Menu>
);

const initialStateLinks = [
  {
    to: '/modules-plans',
    label: 'Módulos e Planos',
  },
  {
    to: '/customers',
    label: 'Clientes',
  },
  {
    to: '/users',
    label: 'Usuários',
  },
];

function LayoutMaster(props) {
  const { children, profile } = props;
  const [links, setLinks] = useState(initialStateLinks);

  useEffect(() => {
    if (
      !(Object.keys(profile).length === 0 && profile.constructor === Object)
    ) {
      const roleMaster =
        profile.roles && profile.roles.find(role => role.slug === 'master');

      if (!roleMaster) {
        setLinks(initialStateLinks.filter(link => link.to === '/customers'));
        return;
      }
      setLinks(initialStateLinks);
    }
  }, [profile, profile.roles]);

  return (
    <StyledLayout className="layout">
      <Header id="components-layout-header">
        <div className="left-header">
          <div className="logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['/customers']}
            style={{ lineHeight: '64px' }}
          >
            {links.map(link => (
              <Menu.Item key={link.to}>
                <NavLink to={link.to}>{link.label}</NavLink>
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className="right-header">
          <Dropdown overlay={menu}>
            <div id="avatar-profile">
              <Avatar icon="user" />
              <span>{profile.username}</span>
            </div>
          </Dropdown>
        </div>
      </Header>

      <Content style={{ padding: '0 50px', height: '100%' }}>
        {children}
      </Content>
    </StyledLayout>
  );
}

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(LayoutMaster);
