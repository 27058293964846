import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import profile from './profile'
import customer from './customer'
import unit from './unit'

export default history => combineReducers({
  router: connectRouter(history),
  auth,
  profile,
  customer,
  unit,
})
