import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { Menu, Icon } from 'antd';

const { SubMenu } = Menu;

const Sidebar = ({ location }) => {
  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location.pathname]);

  const links = [
    {
      id: '/dashboard',
      label: 'Dashboard',
      exact: true,
      activeClassName: 'active',
      to: {
        pathname: '/dashboard',
      },
    },
    {
      id: '/dashboard/plans',
      label: 'Planos',
      exact: true,
      activeClassName: 'active',
      to: {
        pathname: '/dashboard/plans',
      },
    },
    {
      id: '/dashboard/log',
      label: 'Log',
      exact: true,
      activeClassName: 'active',
      to: {
        pathname: '/dashboard/log',
      },
    },
    {
      id: '/configuration',
      label: 'Configuração',
      exact: true,
      activeClassName: 'active',
      icon: 'appstore',
      to: {
        pathname: '/dashboard/log',
      },
      children: [
        {
          id: '/configuration/date-hour',
          label: 'Data e Hora',
          exact: true,
          activeClassName: 'active',
          to: {
            pathname: '/dashboard/date-hour',
          },
        },
        {
          id: '/configuration/users',
          label: 'Usuários',
          exact: true,
          activeClassName: 'active',
          to: {
            pathname: '/dashboard/users',
          },
        },
      ],
    },
  ];

  const renderMenuItem = menuItem => {
    if (menuItem.children && menuItem.children.length) {
      return (
        <SubMenu
          key={menuItem.id}
          title={
            <span>
              <Icon type={menuItem.icon} />
              <span>{menuItem.label}</span>
            </span>
          }
        >
          {menuItem.children.map(renderMenuItem)}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={menuItem.id}>
        <NavLink {...menuItem}>{menuItem.label}</NavLink>
      </Menu.Item>
    );
  };

  return (
    <Menu
      // style={{ width: '100%', height: '100%' }}
      selectedKeys={selectedKeys}
      defaultOpenKeys={['/configuration']}
      theme="light"
      mode="inline"
    >
      {links.map(renderMenuItem)}
    </Menu>
  );
};

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(Sidebar);
