import React from 'react';
import PropTypes from 'prop-types';
import { Layout, PageHeader } from 'antd';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Sidebar from './Sidebar';

const { Sider, Content } = Layout;

const LayoutBase = ({ children, history, customer, unit }) => (
  <div
    style={{
      height: '100%',
      background: '#fff',
    }}
  >
    <Helmet>
      <title>{`${customer.name} - ${unit.name}`}</title>
    </Helmet>
    <PageHeader
      onBack={() => history.push('/customers')}
      title={`${customer.name} - ${unit.name}`}
      subTitle="Dashboard de gerenciamento de clientes"
    />

    <Layout
      style={{
        height: '100%',
        paddingTop: 10,
        background: '#fff',
      }}
    >
      <Sider style={{ background: '#fff' }}>
        <Sidebar />
      </Sider>
      <Layout style={{ background: '#fff', paddingTop: 10 }}>
        <Content
          style={{
            padding: '10px 24px',
            background: '#fff',
            // minHeight: '700px',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  </div>
);

LayoutBase.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  customer: state.customer,
  unit: state.unit,
});

export default connect(mapStateToProps)(withRouter(LayoutBase));
