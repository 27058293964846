import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import expireReducer from 'redux-persist-expire'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

// Import all sagas
import sagas from './sagas'
// Import all reducers ducks
import reducers from './ducks'
// Import Toast Middleware
// import toast from './toast'

const persistConfig = {
  key: '@Intelup:portal',
  storage,
  whitelist: ['auth', 'profile', 'customer', 'unit'],
  transforms: [
    expireReducer('auth', {
      expireSeconds: 60 * 60 * 24,
    }),
  ],
}

// Initialize middlewares array
const middlewares = []

// Create middleware
const sagaMiddleware = createSagaMiddleware()

export const history = createBrowserHistory()

middlewares.push(sagaMiddleware)
// middlewares.push(toast)
middlewares.push(routerMiddleware(history))

// Compose middleware and Redux DevTools
const composeEnhancers = process.env.NODE_ENV === 'development'
  && typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose

const persistedReducer = persistReducer(persistConfig, reducers(history))

// Create store with middlwares
const Store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))

const persistor = persistStore(Store)

// Initialize middleware
sagaMiddleware.run(sagas)

export { Store, persistor }
