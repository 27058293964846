import { all, takeLatest } from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist/lib/constants'

import { Types as AuthTypes } from '../ducks/auth'

import { login, signup } from './auth'
import { init } from './init'

export default function* rootSaga() {
  yield takeLatest(REHYDRATE, init)
  yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(AuthTypes.SIGNUP_REQUEST, signup),
  ])
}
