import axios from 'axios'

const api = axios.create({
  baseURL: window._env_.API_URL || 'https://api.intelup.com.br/',
})

api.postOrPut = (url, id, data, config = {}) => {
  const method = id ? 'put' : 'post'
  const apiUrl = id ? `${url}/${id}` : url

  return api[method](apiUrl, data, config)
}

export default api
