import api from 'services/api'

export const Types = {
  SET_DATA: 'unit/SET_DATA',
}

const INITIAL_STATE = {}

export default function unit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_DATA:
      // plt_codigo: data.plt_codigo
      api.defaults.headers.common.plt_codigo = action.payload.code
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export const Creators = {
  setUnitData: data => ({ type: Types.SET_DATA, payload: data }),
}
