import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Button } from 'antd';

import { Container, Input, InputContainer, Logo } from './styles';
import imageLogo from '../../assets/images/logo-white.png';

import { Creators as AuthActions } from '../../store/ducks/auth';
import { Store } from '../../store';

Store.dispatch(AuthActions.clearError());

const Login = ({
  values,
  handleSubmit,
  handleChange,
  errors,
  auth,
  profile,
}) => {
  if (auth.token) {
    ReactGA.set({ user_id: profile.id });

    return <Redirect to="customers" />;
  }

  return (
    <Container>
      <form noValidate onSubmit={handleSubmit}>
        <Logo src={imageLogo} alt="Logo" height={72} />
        <InputContainer>
          <Input
            type="text"
            id="username"
            placeholder="Seu usuário"
            required
            autoFocus
            name="username"
            value={values.username}
            onChange={handleChange}
          />

          <div className="error-feedback">
            {!!errors.username && <span>{errors.username}</span>}
          </div>
        </InputContainer>
        <InputContainer>
          <Input
            type="password"
            id="inputPassword"
            placeholder="Sua senha"
            required
            name="password"
            value={values.password}
            onChange={handleChange}
          />
          <div className="error-feedback ">
            {!!errors.password && <span>{errors.password}</span>}
          </div>
        </InputContainer>
        <InputContainer>
          <label className="font-white" htmlFor="remember">
            <input
              type="checkbox"
              id="remember"
              name="remember"
              value={values.remember}
              onChange={handleChange}
            />{' '}
            Lembre-me
          </label>
        </InputContainer>

        <span className="error-feedback">{auth.error && auth.error}</span>

        <Button
          type="primary"
          htmlType="submit"
          block
          size="large"
          loading={auth.loading}
        >
          {' '}
          Entrar
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

// export default Login
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    mapPropsToValues: () => ({
      username: '',
      password: '',
      remember: false,
    }),

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: Yup.object().shape({
      username: Yup.string().required('Por favor, digite o usuário.'),
      password: Yup.string().required('Por favor, digite a senha.'),
    }),

    handleSubmit: (values, { props }) => {
      props.loginRequest(values);
    },
  })
)(Login);
